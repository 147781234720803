import { default as _403jpGCCxCC90Meta } from "/Volumes/Kooci/find-mentor/find-mentor-web/pages/403.vue?macro=true";
import { default as _404t7qaCbOJydMeta } from "/Volumes/Kooci/find-mentor/find-mentor-web/pages/404.vue?macro=true";
import { default as base2ca98OC75aMeta } from "/Volumes/Kooci/find-mentor/find-mentor-web/pages/contract/base.vue?macro=true";
import { default as contractzH8QBagVfxMeta } from "/Volumes/Kooci/find-mentor/find-mentor-web/pages/contract.vue?macro=true";
import { default as basefgNmlh5xI0Meta } from "/Volumes/Kooci/find-mentor/find-mentor-web/pages/curriculum/base.vue?macro=true";
import { default as curriculumgwwd8xghUMMeta } from "/Volumes/Kooci/find-mentor/find-mentor-web/pages/curriculum.vue?macro=true";
import { default as baseoQZ23bYdtoMeta } from "/Volumes/Kooci/find-mentor/find-mentor-web/pages/find/base.vue?macro=true";
import { default as findirnw1WOmeBMeta } from "/Volumes/Kooci/find-mentor/find-mentor-web/pages/find.vue?macro=true";
import { default as indexb4HGUHlRiFMeta } from "/Volumes/Kooci/find-mentor/find-mentor-web/pages/index.vue?macro=true";
import { default as baseVxgfXzrHUGMeta } from "/Volumes/Kooci/find-mentor/find-mentor-web/pages/info/base.vue?macro=true";
import { default as infojzFrOYmXBzMeta } from "/Volumes/Kooci/find-mentor/find-mentor-web/pages/info.vue?macro=true";
import { default as loginTa0MesHYO3Meta } from "/Volumes/Kooci/find-mentor/find-mentor-web/pages/login.vue?macro=true";
export default [
  {
    name: "403",
    path: "/403",
    component: () => import("/Volumes/Kooci/find-mentor/find-mentor-web/pages/403.vue").then(m => m.default || m)
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/Volumes/Kooci/find-mentor/find-mentor-web/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "contract",
    path: "/contract",
    component: () => import("/Volumes/Kooci/find-mentor/find-mentor-web/pages/contract.vue").then(m => m.default || m),
    children: [
  {
    name: "contract-base",
    path: "base",
    component: () => import("/Volumes/Kooci/find-mentor/find-mentor-web/pages/contract/base.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "curriculum",
    path: "/curriculum",
    component: () => import("/Volumes/Kooci/find-mentor/find-mentor-web/pages/curriculum.vue").then(m => m.default || m),
    children: [
  {
    name: "curriculum-base",
    path: "base",
    component: () => import("/Volumes/Kooci/find-mentor/find-mentor-web/pages/curriculum/base.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "find",
    path: "/find",
    component: () => import("/Volumes/Kooci/find-mentor/find-mentor-web/pages/find.vue").then(m => m.default || m),
    children: [
  {
    name: "find-base",
    path: "base",
    component: () => import("/Volumes/Kooci/find-mentor/find-mentor-web/pages/find/base.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexb4HGUHlRiFMeta || {},
    component: () => import("/Volumes/Kooci/find-mentor/find-mentor-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/Volumes/Kooci/find-mentor/find-mentor-web/pages/info.vue").then(m => m.default || m),
    children: [
  {
    name: "info-base",
    path: "base",
    component: () => import("/Volumes/Kooci/find-mentor/find-mentor-web/pages/info/base.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/Volumes/Kooci/find-mentor/find-mentor-web/pages/login.vue").then(m => m.default || m)
  }
]