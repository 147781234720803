import type { UserDocument } from "~/types/user.type";

export const useUser = defineStore("user", {
    state: () => ({
        /** 姓名 */
        uname: "",
        /** 登录签名 */
        token: localStorage.getItem("token") || "",
        /** 头像 */
        avatar: "",
        /** 人员id */
        uid: "",
        /** 权限列表 */
        permissions: [] as string[],
        /** 角色列表 */
        roles: [] as string[],
        /** 电话 */
        phone: "",
    }),
    actions: {
        /** 更新头像 */
        setAvatar(avatar: string) {
            this.avatar = avatar;
        },
        /** 设置姓名 */
        setUname(uname: string) {
            this.uname = uname;
        },
        /** 设置登录签名 */
        setToken(token: string) {
            this.token = token;
            localStorage.setItem("token", token);
        },
        /** 设置用户信息 */
        setUser(user: UserDocument) {
            this.uname = user.uname;
            this.setToken(user.token);
            this.avatar = user.avatar;
            this.roles = user.roles;
            this.permissions = user.permissions;
            this.uid = user._id;
            this.phone = user.phone;
        },
        /** 清除登录信息 */
        clearLogin() {
            this.token = "";
            localStorage.removeItem("token");
        },
        /** 清除用户信息 */
        clearUser() {
            this.uname = "";
            this.avatar = "";
            this.uid = "";
            this.clearLogin();
        },
        /** 初始化用户信息 */
        async initUser(): Promise<UserDocument> {
            const uname = this.uname;
            let userInfo = {} as UserDocument;
            if (uname) {
                userInfo = {
                    _id: this.uid,
                    uname: this.uname,
                    avatar: this.avatar,
                    roles: this.roles,
                    phone: this.phone,
                    permissions: this.permissions,
                    token: this.token,
                };
            } else {
                const res = (await $axios.get("/user/info").catch((err) => {
                    throw err;
                })) as any;
                userInfo = res as UserDocument;
                this.setUser(userInfo);
            }
            return userInfo;
        },
    },
    getters: {
        /** 是否为管理员 */
        isAdmin(): boolean {
            return this.roles.includes("admin") || this.permissions.includes("all");
        },
    },
});
