import axios, { AxiosError } from "axios";

export const $axios = axios.create({
    baseURL: "/api",
    headers: {
        "Content-Type": "application/json",
    },
});

/**
 * 扩展Error类
 * @param message 错误信息
 * @param code 错误码
 * @param data 错误数据
 */
export class ErrorExtend extends Error {
    code: number;
    data: any;
    constructor(message: string, code: number, data: any) {
        super(message);
        this.code = code;
        this.data = data;
    }
}

export function useAxios(router: any, route: any, user: any) {
    // 添加请求拦截器,统一添加token
    $axios.interceptors.request.use((config) => {
        config.headers.Authorization = localStorage.getItem("token");
        return config;
    });

    $axios.interceptors.response.use(
        (response) => {
            if (response.status === 200 || response.status === 201) {
                if (response.data.code === 0) {
                    return response.data.data;
                } else {
                    const error = new ErrorExtend(response.data.message, response.data.code, response.data.data);
                    return Promise.reject(error);
                }
            } else {
                return Promise.reject(response.data);
            }
        },
        (err: any) => {
            if (/timeout exceeded/i.test(err.message)) {
                return Promise.reject(new ErrorExtend("请求超时,请检查网络", 504, null));
            }
            if (err.response?.status === 401) {
                user.clearUser();
                err = new Error("登录已过期,请重新登录~");
                if (!location.href.includes("/login")) {
                    const query: { redirect?: string } = {};
                    if (route.path !== "/") {
                        query["redirect"] = encodeURI(route.path);
                    }
                    router.push({ path: "/login", query });
                }
            }
            if (err.response?.status === 403) {
                err = new Error(err.response?.data?.message || "抱歉,您没有权限访问~");
                if (!location.href.includes("/403") && !location.href.includes("/login")) {
                    router.push({ path: "/403", replace: true, query: { message: err.message } });
                    user.clearLogin();
                }
            }
            return Promise.reject(err.response?.data || err);
        },
    );
}
