import { VXETable, VxeTable, VxeColumn } from "vxe-table";
import "vxe-table/lib/style.css";
import { useDark } from "@vueuse/core";

const isDark = useDark();

// watch(
//     isDark,
//     (value) => {
//         VXETable.setConfig({
//             theme: value ? "dark" : "default",
//         });
//     },
//     { immediate: true },
// );

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(VxeTable);
    nuxtApp.vueApp.use(VxeColumn);
});
