export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, user-scalable=no"},{"hid":"description","name":"description","content":"找导师网 - 高校教授在线辅导,要考研就找导师1对1"},{"hid":"keywords","name":"keywords","content":"导师, 找导师, 导师网, 教育, 学习, 考研, 上岸, 找教授"},{"name":"robots","content":"index, follow"},{"hid":"og:title","property":"og:title","content":"找导师网"},{"hid":"og:description","property":"og:description","content":"高校教授在线辅导,要考研就找导师1对1"},{"hid":"og:image","property":"og:image","content":"https://kqlib.kooci.net/public/find-mentor/logo0-300.png"},{"hid":"og:url","property":"og:url","content":"https://www.zhaodaoshi.com"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"twitter:card","name":"twitter:card","content":"summary_large_image"},{"hid":"twitter:title","name":"twitter:title","content":"找导师网"},{"hid":"twitter:description","name":"twitter:description","content":"高校教授在线辅导,要考研就找导师1对1"},{"hid":"twitter:image","name":"twitter:image","content":"/path/to/image.jpg"}],"link":[{"rel":"stylesheet","href":"//at.alicdn.com/t/c/font_4637212_knd0qayamdi.css"}],"style":[],"script":[],"noscript":[],"title":"找导师网"}

export const appPageTransition = {"enterActiveClass":"page-transition page-transition--enter animate__animated animate__fadeInDown","leaveActiveClass":"page-transition page-transition--leave animate__animated animate__fadeOut","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000