import revive_payload_client_VRyLVCpleK from "/Volumes/Kooci/find-mentor/find-mentor-web/node_modules/.pnpm/nuxt@3.12.4_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sGrm1xpwv4 from "/Volumes/Kooci/find-mentor/find-mentor-web/node_modules/.pnpm/nuxt@3.12.4_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_za1Y8QxVgd from "/Volumes/Kooci/find-mentor/find-mentor-web/node_modules/.pnpm/nuxt@3.12.4_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_l55LYPsJlY from "/Volumes/Kooci/find-mentor/find-mentor-web/node_modules/.pnpm/nuxt@3.12.4_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_pb5sx5VTUJ from "/Volumes/Kooci/find-mentor/find-mentor-web/node_modules/.pnpm/nuxt@3.12.4_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_PfUw8dZPnN from "/Volumes/Kooci/find-mentor/find-mentor-web/node_modules/.pnpm/nuxt@3.12.4_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_HGts6P6PoR from "/Volumes/Kooci/find-mentor/find-mentor-web/node_modules/.pnpm/nuxt@3.12.4_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_J4qBtItmLL from "/Volumes/Kooci/find-mentor/find-mentor-web/node_modules/.pnpm/@pinia+nuxt@0.5.2_vue@3.4.34/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Volumes/Kooci/find-mentor/find-mentor-web/.nuxt/components.plugin.mjs";
import prefetch_client_BiBq48hj60 from "/Volumes/Kooci/find-mentor/find-mentor-web/node_modules/.pnpm/nuxt@3.12.4_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import VxeTable_v7YQqcJYQw from "/Volumes/Kooci/find-mentor/find-mentor-web/plugins/VxeTable.ts";
import element_icon_eHTffnbFLE from "/Volumes/Kooci/find-mentor/find-mentor-web/plugins/element-icon.ts";
export default [
  revive_payload_client_VRyLVCpleK,
  unhead_sGrm1xpwv4,
  router_za1Y8QxVgd,
  payload_client_l55LYPsJlY,
  navigation_repaint_client_pb5sx5VTUJ,
  check_outdated_build_client_PfUw8dZPnN,
  chunk_reload_client_HGts6P6PoR,
  plugin_vue3_J4qBtItmLL,
  components_plugin_KR1HBZs4kY,
  prefetch_client_BiBq48hj60,
  VxeTable_v7YQqcJYQw,
  element_icon_eHTffnbFLE
]