<template>
    <img src="https://kqlib.kooci.net/public/find-mentor/logo0-300.png" style="display: none" />
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<script setup>
//////////////////////////////////////////////////////////////////////////////////////////////////// axios初始化
const router = useRouter();
const route = useRoute();
const user = useUser();
useAxios(router, route, user);

//////////////////////////////////////////////////////////////////////////////////////////////////// 插件皮肤
onMounted(() => {
    document.body.style.setProperty("--el-color-primary", "#FDB205");
    document.body.style.setProperty("--el-color-primary-light-3", "#ffc53e");
    document.body.style.setProperty("--el-color-primary-light-5", "#ffd165");
    document.body.style.setProperty("--el-color-primary-light-7", "#f9d786");
    document.body.style.setProperty("--el-color-primary-light-8", "#fee099");
    document.body.style.setProperty("--el-color-primary-light-9", "#fde9bc");
    document.body.style.setProperty("--el-color-primary-dark-2", "#e19c00");
});
</script>

<style lang="scss">
body {
    font-size: 14px;
    line-height: 1.6;
    color: #333;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
}
#__nuxt {
    width: 100%;
}
// 使用样式隐藏body的滚动条(不去除,依然可以滚动)
body::-webkit-scrollbar {
    display: none;
}
.page-transition--enter {
    animation-duration: 0.2s;
}
.page-transition--leave {
    animation-duration: 0.2s;
}
.fm-layout {
    // @apply w-full px-4 md:w-11/12 2xl:w-10/12;
    @apply w-full px-4;
    @media (min-width: 1350px) {
        width: 90%;
    }
    @media (min-width: 1500px) {
        width: 80%;
    }
    @media (min-width: 1700px) {
        width: 70%;
    }
    @media (min-width: 2100px) {
        width: 65%;
    }
}
</style>
